import {dimensions, measures} from './qlik/klant/klant';
export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Debiteur',
          value: 'debiteur',
          children: [
            {
              qlik: dimensions.debtor.name,
              label: 'Naam',
              value: 'vD_Order.Debtor.Name'
            },
            {
              qlik: dimensions.debtor.number,
              label: 'Nummer',
              value: 'vD_Order.Debtor.Number'
            }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              qlik: dimensions.order.number,
              value: 'vD_Order.OrderNumber',
              label: 'Technisch %Order%'
            },
            {
              qlik: dimensions.order.ordernumberdisplay,
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Zending'
            },
            {
              qlik: dimensions.order.kinddescription,
              value: 'vD_Order.KindDescription',
              label: 'Status'
            }

          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              qlik: dimensions.loadinglocation.id,
              value: 'vD_Order.LoadingLocation.ID',
              label: 'ID'
            },
            {
              qlik: dimensions.loadinglocation.name,
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam'
            },
            {
              qlik: dimensions.loadinglocation.adress,
              value: 'vD_Order.LoadingLocation.Adress',
              label: 'Adres'
            },
            {
              qlik: dimensions.loadinglocation.zipcode,
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              qlik: dimensions.loadinglocation.city, 
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats'
            },
            {
              qlik: dimensions.loadinglocation.country, 
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              qlik: dimensions.unloadinglocation.id, 
              value: 'vD_Order.UnloadingLocation.ID',
              label: 'ID'
            },
            {
              qlik: dimensions.unloadinglocation.name, 
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam'
            },
            {
              qlik: dimensions.unloadinglocation.adress,
              value: 'vD_Order.UnloadingLocation.Adress',
              label: 'Adres'
            },
            {
              qlik: dimensions.unloadinglocation.zipcode,
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              qlik: dimensions.unloadinglocation.city, 
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats'
            },
            {
              qlik: dimensions.unloadinglocation.country, 
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land'
            }
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              qlik: dimensions.period.year, 
              value: 'vD_Year',
              label: 'Jaar'
            },
            {
              qlik: dimensions.period.quarter,
              value: 'vD_Quarter',
              label: 'Kwartaal'
            },
            {
              qlik: dimensions.period.month, 
              value: 'vD_Month',
              label: 'Maand'
            },
            {
              qlik: dimensions.period.period,
              value: 'vD_Period',
              label: 'Periode'
            },
            {
              qlik: dimensions.period.week, 
              value: 'vD_Week',
              label: 'Week'
            },
            {
              qlik: dimensions.period.date, 
              value: 'vD_Date',
              label: 'Datum'
            }
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              qlik: measures.omzet.totaal,
              value: 'vE_OrderOmzet',
              label: 'Totaal'
            }
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              qlik: measures.kosten.totaal,
              value: 'vE_OrderKosten',
              label: 'Totaal',
            },
            {
              qlik: measures.kosten.charter, 
              value: 'vE_OrderKostenCharter',
              label: 'Charter',
            },
            {
              qlik: measures.kosten.toll, 
              value: 'vE_OrderKostenTol',
              label: 'Tol',
            },
            {
              qlik: measures.kosten.warehouse, 
              value: 'vE_OrderKostenWarehouse',
              label: 'Warehouse',
            },
            {
              qlik: measures.kosten.other, 
              value: 'vE_OrderKostenOther',
              label: 'Other',
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              qlik: measures.aantal.dossiers,
              value: 'vE_OrderAantalDossiers',
              label: 'Dossiers',
            },
            {
              qlik: measures.aantal.orders, 
              value: 'vE_OrderAantalOrders',
              label: 'Zendingen',
            },
            {
              qlik: measures.aantal.ritten, 
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              qlik: measures.kpi.goedkeur,
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK'
            },
  
            {
              qlik: measures.kpi.normmarginpercent,
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %'
            },
            {
              qlik: measures.kpi.normmarginomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge'
            },
  
  
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              qlik: measures.own.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur'
            },
            {
              qlik: measures.own.tripomzetperuur,
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit'
            },
            {
              qlik: measures.own.normomzetperuur,
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur'
            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              qlik: measures.outsourced.percentage,
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed'
            },
            {
              qlik: measures.outsourced.marge, 
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge'
            },
            {
              qlik: measures.outsourced.norm,
              value: 'vE_OrderNormCharter',
              label: 'Norm'
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              qlik: measures.km.totaal,
              value: 'vE_OrderKM',
              label: 'Totaal'
            },
            {
              qlik: measures.km.omzetperkm,
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM'
            },
            {
              qlik: measures.km.normomzetperkm,
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              qlik: measures.uren.totaal, 
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
            },
            {
              qlik: measures.uren.loading,
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
            },
            {
              qlik: measures.uren.unloading, 
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
            },
            {
              qlik: measures.uren.driving, 
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
            },
            {
              qlik: measures.uren.other, 
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
            },
            {
              qlik: measures.uren.avg_totaal,
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
            },
            {
              qlik: measures.uren.avg_loading,
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
            },
            {
              qlik: measures.uren.avg_unloading,
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
            },
            {
              qlik: measures.uren.avg_driving, 
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
            },
            {
              qlik: measures.uren.avg_other,
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',
            }
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Voorgaand jaar',
          children: [
            {
              qlik: measures.previousyear.omzet,
              value: 'vE_OrderOmzetVorigJaar',
              label: 'Omzet',
            },
            {
              qlik: measures.previousyear.orders,
              value: 'vE_OrderAantalOrdersVorigJaar',
              label: 'Order',
            },
            {
              qlik: measures.previousyear.ritten,
              value: 'vE_OrderAantalRittenVorigJaar',
              label: 'Ritten',
            },
            {
              qlik: measures.previousyear.goedkeur,
              value: 'vE_KPI_OrderGoedkeurVorigJaar',
              label: 'OK'
            },
  
            {
              qlik: measures.previousyear.normmarge,
              value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
              label: 'Norm Marge %'
            },
            {
              qlik: measures.previousyear.normmargeomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
              label: 'Norm Marge'
            },
            {
              qlik: measures.previousyear.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
              label: 'Omzet / Uur'
            },
            {
              qlik: measures.previousyear.outsourced,
              value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
              label: '% Uitbesteed'
            },
            {
              qlik: measures.previousyear.chargermarge,
              value: 'vE_KPI_OrderCharterMargeVorigJaar',
              label: 'Derden Marge'
            },
  
          ],
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              qlik: measures.persize.shipmentsize,
              value: 'vE_OrderShipmentSize',
              label: 'LM'
            },
            {
              qlik: measures.persize.shipmentsizeavg,
              value: 'vE_OrderShipmentSizeAvg',
              label: 'Gemiddelde LM'
            },
            {
              qlik: measures.persize.kg,
              value: 'vE_OrderKG',
              label: 'KG'
            },
            {
              qlik: measures.persize.avg_kg,
              value: 'vE_OrderKGAvg',
              label: 'Gemiddelde KG'
            },
            {
              qlik: measures.persize.omzet_shipmentsize,
              value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
              label: 'Omzet / LM'
            },
            {
              qlik: measures.persize.loading_shipmentsize,
              value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
              label: 'Laden / LM'
            },
            {
              qlik: measures.persize.unloading_shipmentsize, 
              value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
              label: 'Lossen / LM'
            },
            {
              qlik: measures.persize.time_shipmentsize,
              value: 'vE_KPI_OrderMinutenPerZendingGrootte',
              label: 'Tijd / LM'
            },
          ]
        },
      ]
    }
  ];
  