import React from 'react';

import orderList from './../../margemonitor/components/orders/dimensions-JKS';
import ritList from './../../margemonitor/components/ritten/dimensions-JKS';
import orderTripList from './../../margemonitor/components/trajecten/dimensions-JKS';
const NarekenenKPI = React.lazy(() => import('./../../views/Maatwerk/JKS/NarekenenKPI'));

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'transportcostconsult.eu.qlikcloud.com',
    secure: true,
    prefix: '',
    webIntegrationId: 'sNTPmFtl_DewysNPz7sliEgocT0P7Dlp',  
    appId: '1b905c50-11ba-4e42-9aa7-c07cf99e46bc', 
  },
  configOld: {
    host: 'qliksense.jankrediet.com',
    secure: true,
    //port: 443,
    prefix: '',
    //appId: '053905f2-27f8-4f1f-bfb7-18defccfd112',
    appId: '45616010-7ae4-4e3e-897b-bb8bb9c7dcf8'
  },
  connections: {
    qlikCloud: true, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Year)]'],
            placeholder: 'Jaar',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Quarter)]'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Month)]'],
            placeholder: 'Maand',
            expanded: true, 
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Week)]'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },  
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Date)]'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Region)]'],
            placeholder: 'Regio',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.FinancialRegion)]'],
            placeholder: 'F-Regio - Code',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.FinancialRegionDescription)]'],
            placeholder: 'F-Regio - Omschrijving',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.OperationalRegion)]'],
            placeholder: 'O-Regio - Code',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.OperationalRegionDescription)]'],
            placeholder: 'O-Regio - Omschrijving',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Region)]'],
            placeholder: 'Regio',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.FinancialRegion)]'],
            placeholder: 'F-Regio - Code',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.FinancialRegionDescription)]'],
            placeholder: 'F-Regio - Omschrijving',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.OperationalRegion)]'],
            placeholder: 'O-Regio - Code',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.OperationalRegionDescription)]'],
            placeholder: 'O-Regio - Omschrijving',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Ordernummer',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_^JKS.Order.Dossier)]'],
            placeholder: 'Dossier',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Debiteur'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Debtor.Name)'],
            placeholder: 'Naam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.FinancialNumber)]'],
            placeholder: 'Financieelnummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_^Order.AccountManager)]'],
            placeholder: 'AccountManager',
            showStateInDropdown: true,
          },
        },
        {
          type: 'title',
          title: 'Afdeling'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Division)]'],
            placeholder: 'Afdeling',
            //expanded: true,
            //expandedHorizontal: true, 
            //expandedHorizontalSense: true,
            showStateInDropdown: true,
            //autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_^JKS.Order.Trip.PlanAfdeling.LoadingTrip)]'],
            placeholder: 'Laadafdeling',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_^JKS.Order.Trip.PlanAfdeling.UnloadingTrip)]'],
            placeholder: 'Losafdeling',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_^Order.RelatieBeheerder)]'],
            placeholder: 'Relatiebeheerder',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_^Order.Team)]'],
            placeholder: 'Team',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Kenmerken'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Kind)]'],
            placeholder: 'Order soort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.ServiceDescription)]'],
            placeholder: 'Service',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD__OrderInvoiced)]'],
            placeholder: 'Gefactureerd',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.XDock)]'],
            placeholder: 'X-Dock',
            showStateInDropdown: true,
          },
        },
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Department)]'],
            placeholder: 'Afdeling',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Chartersoort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Crediteur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Crediteur naam',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
        {
          title: true,
          name: '',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          title: true,
          name: 'Maatwerk',
          wrapper: {
            element: '',
            attributes: {},
          },
        },
        {
          name: 'Narekenen KPI',
          url: '/frv/narekenen-kpi',
          icon: 'fa fa-calculator'
        },
        //{
        //  name: 'Onregelmatigheden',
        //  url: '/jks/onregelmatigheden',
        //  icon: 'fa fa-exclamation',
        //},
        //{
        //  name: 'Aansluiting Facturen',
        //  url: '/jks/aansluiting-facturen',
        //  icon: 'fa fa-file-invoice',
        //},
        //{
        //  name: 'Accountant',
        //  url: '/jks/accountant',
        //  icon: 'fa fa-calculator',
        //},
      ],
  },    
  routes: [
    { path: '/frv/narekenen-kpi', name: 'FRV / Narekenen KPI', component: NarekenenKPI },

  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  defaultSelections: [
    {
      field: 'Order > Gefactureerd',
      expression: '=text(Ja)'
    },
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
    
  ]
};
export default options;