import {dimensions, measures} from './qlik/klant/klant';
//import {dimensions as wij_dimensions, measures as wij_measures} from './qlik/klant/wij';

export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Klant',
          value: 'klant',
          children: [
            {
              qlik: dimensions.debtor.name,
              label: 'Naam',
              value: 'vD_Order.Debtor.Name'
            },
            {
              qlik: dimensions.debtor.number,
              label: 'Nummer',
              value: 'vD_Order.Debtor.Number'
            },
            {
              //qlik: dimensions.de
              label: 'Groep',
              value: 'vD_Order.Debtor.Group'
            },
            {
              label: 'Financieelnummer',
              value: 'vD_Order.Debtor.FinancialNumber'
            },
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              qlik: dimensions.order.ordernumberdisplay,
              value: 'vD_Order.OrderNumberDisplay',
              label: 'Order Nummer'
            },
          ]
        },
        {
          label: 'Overig',
          value: 'overig',
          children: [
            {
              value: 'vD_Order.ServiceDescription',
              label: 'Service'
            },
            {
              value: 'vD_Order.Kind',
              label: 'Ordersoort'
            },
            {
              value: 'vD_^Order.AccountManager',
              label: 'Accountmanager'
            },
            {
              value: 'vD_^Order.RelatieBeheerder',
              label: 'Relatiebeheerder'
            },
            {
              value: 'vD_^Order.Team',
              label: 'Team'
            },
            {
              value: 'vD_^Order.SalesBy',
              label: 'Sales By'
            },
            {
              value: 'vD_Order.Afzender',
              label: 'Afzender'
            },
            {
              value: 'vD_Order.Division',
              label: 'Afdeling'
            },
          ]
        },
        {
          label: 'Laad Locatie',
          value: 'laadlocatie',
          children: [
            {
              qlik: dimensions.loadinglocation.id,
              value: 'vD_Order.LoadingLocation.ID',
              label: 'ID'
            },
            {
              qlik: dimensions.loadinglocation.name,
              value: 'vD_Order.LoadingLocation.Name',
              label: 'Naam'
            },
            {
              qlik: dimensions.loadinglocation.adress,
              value: 'vD_Order.LoadingLocation.Adress',
              label: 'Adres'
            },
            {
              qlik: dimensions.loadinglocation.zipcode,
              value: 'vD_Order.LoadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              qlik: dimensions.loadinglocation.city,
              value: 'vD_Order.LoadingLocation.City',
              label: 'Plaats'
            },
            {
              qlik: dimensions.loadinglocation.region,
              value: 'vD_Order.LoadingLocation.Region',
              label: 'Regio'
            },
            {
              qlik: dimensions.loadinglocation.financialregion,
              value: 'vD_Order.LoadingLocation.FinancialRegion',
              label: 'F-Regio - Code'
            },
            {
              qlik: dimensions.loadinglocation.financialregiondescription,
              value: 'vD_Order.LoadingLocation.FinancialRegionDescription',
              label: 'F-Regio - Omschrijving'
            },
            {
              qlik: dimensions.loadinglocation.operationalregion,
              value: 'vD_Order.LoadingLocation.OperationalRegion',
              label: 'O-Regio - Code'
            },
            {
              qlik: dimensions.loadinglocation.operationalregiondescription,
              value: 'vD_Order.LoadingLocation.OperationalRegionDescription',
              label: 'O-Regio - Omschrijving'
            },
            {
              qlik: dimensions.loadinglocation.country,
              value: 'vD_Order.LoadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Los Locatie',
          value: 'loslocatie',
          children: [
            {
              qlik: dimensions.unloadinglocation.id,
              value: 'vD_Order.UnloadingLocation.ID',
              label: 'ID'
            },
            {
              qlik: dimensions.unloadinglocation.name,
              value: 'vD_Order.UnloadingLocation.Name',
              label: 'Naam'
            },
            {
              qlik: dimensions.unloadinglocation.adress,
              value: 'vD_Order.UnloadingLocation.Adress',
              label: 'Adres'
            },
            {
              qlik: dimensions.unloadinglocation.zipcode,
              value: 'vD_Order.UnloadingLocation.ZIPcode',
              label: 'Postcode'
            },
            {
              qlik: dimensions.unloadinglocation.city,
              value: 'vD_Order.UnloadingLocation.City',
              label: 'Plaats'
            },
            {
              qlik: dimensions.unloadinglocation.region,
              value: 'vD_Order.UnloadingLocation.Region',
              label: 'Regio'
            },
            {
              qlik: dimensions.unloadinglocation.financialregion,
              value: 'vD_Order.UnloadingLocation.FinancialRegion',
              label: 'F-Regio - Code'
            },
            {
              qlik: dimensions.unloadinglocation.financialregiondescription,
              value: 'vD_Order.UnloadingLocation.FinancialRegionDescription',
              label: 'F-Regio - Omschrijving'
            },
            {
              qlik: dimensions.unloadinglocation.operationalregiondescription,
              value: 'vD_Order.UnloadingLocation.OperationalRegion',
              label: 'O-Regio - Code'
            },
            {
              qlik: dimensions.unloadinglocation.operationalregiondescription,
              value: 'vD_Order.UnloadingLocation.OperationalRegionDescription',
              label: 'O-Regio - Omschrijving'
            },
            {
              qlik: dimensions.unloadinglocation.country,
              value: 'vD_Order.UnloadingLocation.Country',
              label: 'Land'
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              qlik: dimensions.period.year,
              value: 'vD_Year',
              label: 'Jaar'
            },
            {
              qlik: dimensions.period.quarter,
              value: 'vD_Quarter',
              label: 'Kwartaal'
            },
            {
              qlik: dimensions.period.month,
              value: 'vD_Month',
              label: 'Maand'
            },
            {
              qlik: dimensions.period.week,
              value: 'vD_Week',
              label: 'Week'
            },
            {
              qlik: dimensions.period.date,
              value: 'vD_Date',
              label: 'Datum'
            },
            {
              qlik: dimensions.period.date,
              value: 'vD_WeekDay',
              label: 'Dag'
            },
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              qlik: measures.omzet.totaal,
              value: 'vE_OrderOmzet',
              label: 'Totaal'
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              qlik: measures.kosten.totaal,
              value: 'vE_OrderKosten',
              label: 'Totaal',
            },
            {
              qlik: measures.kosten.charter,
              value: 'vE_OrderKostenCharter',
              label: 'Derden',
            },
            {
              qlik: measures.kosten.toll,
              value: 'vE_OrderKostenTol',
              label: 'Tol',
            },
            {
              qlik: measures.kosten.codriver,
              value: 'vE_OrderKostenCoDriver',
              label: 'Bijrijder',
            },
            {
              qlik: measures.kosten.warehouse,
              value: 'vE_OrderKostenWarehouse',
              label: 'Warehouse',
            },
            {
              qlik: measures.kosten.other,
              value: 'vE_OrderKostenOther',
              label: 'Other',
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              qlik: measures.aantal.orders,
              value: 'vE_OrderAantalOrders',
              label: 'Order',
            },
            {
              qlik: measures.aantal.ritten,
              value: 'vE_OrderAantalRitten',
              label: 'Ritten',
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              qlik: measures.kpi.goedkeur,
              value: 'vE_KPI_OrderGoedkeur',
              label: 'OK'
            },
  
            {
              qlik: measures.kpi.normmarginpercent,
              value: 'vE_KPI_OrderNormMarginPercent',
              label: 'Norm Marge %'
            },
            {
              qlik: measures.kpi.normmarginomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzet',
              label: 'Norm Marge'
            },
  
  
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              qlik: measures.own.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUur',
              label: 'Omzet / Uur'
            },
            {
              qlik: measures.own.tripomzetperuur,
              value: 'vE_KPI_Order_TripOmzetPerUur',
              label: 'Omzet / Uur - rit'
            },
            {
              qlik: measures.own.bezetting,
              value: 'vE_KPI_OrderBezetting',
              label: 'Bezetting'
            },
            {
              qlik: measures.own.normomzetperuur,
              value: 'vE_OrderNormTurnoverHour',
              label: 'Norm Omzet / Uur'
            },
            {
              value: 'NarekenenEigenWagen',
              label: 'Narekenen KPI',
              children: [
                {
                  value: 'NarekenenEigenWagen.Omzet',
                  label: 'Omzet',
                  children: [
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Totaal'
                    },
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Eigen Werk'
                    },
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Goedgekeurd'
                    },
                  ]
                },
                {
                  value: 'NarekenenEigenWagen.Kosten',
                  label: 'Kosten',
                  children: [
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Totaal'
                    },
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Eigen Werk'
                    },
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Goedgekeurd'
                    },
                  ]
                },
                {
                  value: 'NarekenenEigenWagen.Uren',
                  label: 'Uren',
                  children: [
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Totaal'
                    },
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Eigen Werk'
                    },
                    {
                      qlik: measures.own.normomzetperuur,
                      value: 'vE_OrderNormTurnoverHour',
                      label: 'Goedgekeurd'
                    },
                  ]
                },
                {
                  qlik: measures.own.normomzetperuur,
                  value: 'vE_OrderNormTurnoverHour',
                  label: 'Berekening'
                },
              ]
            }
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              qlik: measures.outsourced.percentage,
              value: 'vE_KPI_OrderTripOutsourced',
              label: '% Uitbesteed'
            },
            {
              qlik: measures.outsourced.marge,
              value: 'vE_KPI_OrderCharterMarge',
              label: 'Derden Marge'
            },
            {
              qlik: measures.outsourced.norm,
              value: 'vE_OrderNormCharter',
              label: 'Norm'
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              qlik: measures.potentie.totaal,
              value: 'vE_OrderPotency',
              label: 'Totaal'
            },
            {
              qlik: measures.potentie.commercieel,
              value: 'vE_OrderPotencyCommercial',
              label: 'Commercieel'
            },
            {
              qlik: measures.potentie.operationeel,
              value: 'vE_OrderPotencyOperational',
              label: 'Operationeel'
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              qlik: measures.km.totaal,
              value: 'vE_OrderKM',
              label: 'Totaal'
            },
            {
              qlik: measures.km.omzetperkm,
              value: 'vE_KPI_OrderOmzetPerKM',
              label: 'Omzet / KM'
            },
            {
              qlik: measures.km.normomzetperkm,
              value: 'vE_OrderNormTurnoverKM',
              label: 'Norm Omzet / KM'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              qlik: measures.uren.totaal,
              value: 'vE_OrderUren',
              label: 'Totaal (Σ)',
            },
            {
              qlik: measures.uren.loading,
              value: 'vE_OrderUrenLoading',
              label: 'Laden (Σ)',
            },
            {
              qlik: measures.uren.unloading,
              value: 'vE_OrderUrenUnloading',
              label: 'Lossen (Σ)',
            },
            {
              qlik: measures.uren.driving,
              value: 'vE_OrderUrenDriving',
              label: 'Rijden (Σ)',
            },
            {
              qlik: measures.uren.other,
              value: 'vE_OrderUrenOther',
              label: 'Overig (Σ)',
            },
            {
              qlik: measures.uren.avg_totaal,
              value: 'vE_OrderUrenAvg',
              label: 'Totaal (avg)',
            },
            {
              qlik: measures.uren.drivingloading,
              value: 'vE_OrderUrenAvgLoading',
              label: 'Laden (avg)',
            },
            {
              qlik: measures.uren.drivingunloading,
              value: 'vE_OrderUrenAvgUnloading',
              label: 'Lossen (avg)',
            },
            {
              qlik: measures.uren.avg_driving,
              value: 'vE_OrderUrenAvgDriving',
              label: 'Rijden (avg)',
            },
            {
              qlik: measures.uren.avg_other,
              value: 'vE_OrderUrenAvgOther',
              label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
            }
          ]
        },
        {
          value: 'vergelijkperiode',
          label: 'Voorgaand jaar',
          children: [
            {
              qlik: measures.previousyear.omzet, 
              value: 'vE_OrderOmzetVorigJaar',
              label: 'Omzet',
            },
            {
              qlik: measures.previousyear.orders,
              value: 'vE_OrderAantalOrdersVorigJaar',
              label: 'Order',
            },
            {
              qlik: measures.previousyear.ritten,
              value: 'vE_OrderAantalRittenVorigJaar',
              label: 'Ritten',
            },
            {
              qlik: measures.previousyear.goedkeur,
              value: 'vE_KPI_OrderGoedkeurVorigJaar',
              label: 'OK'
            },
            {
              qlik: measures.previousyear.normmarge,
              value: 'vE_KPI_OrderNormMarginPercentVorigJaar',
              label: 'Norm Marge %'
            },
            {
              qlik: measures.previousyear.normmargeomzet,
              value: 'vE_KPI_OrderNormMarginOverOmzetVorigJaar',
              label: 'Norm Marge'
            },
            {
              qlik: measures.previousyear.omzetperuur,
              value: 'vE_KPI_OrderOmzetPerUurVorigJaar',
              label: 'Omzet / Uur'
            },
            {
              qlik: measures.previousyear.outsourced,
              value: 'vE_KPI_OrderTripOutsourcedVorigJaar',
              label: '% Uitbesteed'
            },
            {
              qlik: measures.previousyear.chargermarge,
              value: 'vE_KPI_OrderCharterMargeVorigJaar',
              label: 'Derden Marge'
            },
  
          ],
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'm3',
              label: 'M3',
              children: [
                {
                  qlik: measures.persize.shipmentsize,
                  value: 'vE_OrderShipmentSize',
                  label: 'M3'
                },
                {
                  qlik: measures.persize.omzet_shipmentsize,
                  value: 'vE_KPI_OrderOmzetPerZendingGroottePervE_OrderShipmentSize',
                  label: 'Omzet Per M3'
                },
                {
                  qlik: measures.persize.loading_shipmentsize,
                  value: 'vE_KPI_OrderLaadMinutenPerZendingGrootte',
                  label: 'Laad minuten per M3'
                },
                {
                  qlik: measures.persize.unloading_shipmentsize,
                  value: 'vE_KPI_OrderLosMinutenPerZendingGrootte',
                  label: 'Los minuten per M3'
                },
                {
                  qlik: measures.persize.time_shipmentsize,
                  value: 'vE_KPI_OrderMinutenPerZendingGrootte',
                  label: 'Tijd per M3'
                },
              ]
            },
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  value: 'vE_OrderKG',
                  label: 'Totaal',
                  help: 'Het totaal aantal kilo\'s vanuit Plan&Go'
                },
                {
                  value: 'vE_OrderKGAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal kilo\'s vanuit Plan&Go'

                }
              ]
            },
            {
              value: 'colli',
              label: 'Colli',
              children: [
                {
                  value: 'vE_OrderColli',
                  label: 'Totaal',
                  help: 'Het totaal aantal collo vanuit Plan&Go'

                },
                {
                  value: 'vE_OrderColliAvg',
                  label: 'Gemiddelde',
                  help: 'Het gemiddelde aantal collo vanuit Plan&Go'

                }
              ]
            },
          ]
        },
      ]
    }
  ];
  