import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';
import {dimensions as sim_orderdimensions} from '../orders/qlik/klant/sim';
import {measures} from './qlik/qlik';

export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            qlik: orderdimensions.order.ordernumberdisplay,
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Order Nummer'
          }, 
          {
            qlik: sim_orderdimensions.order.department,
            value: 'vD_Order.Department',
            label: 'Afdeling'
          },
          {
            label: 'Klant',
            value: 'Klant',
            children: [
              {
              value: 'vD_Order.Debtor.Name',
              label: "Naam",
              qlik: orderdimensions.debtor.name
              },
              {
              value: 'vD_Order.Customer.Number',
              label: 'Nummer',
              qlik: orderdimensions.debtor.number
              },
              
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                qlik: orderdimensions.loadinglocation.id,
                value: 'vD_Order.LoadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.loadinglocation.name,
                value: 'vD_Order.LoadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.loadinglocation.zipcode,
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.loadinglocation.city,
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.loadinglocation.country,
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land'
              },
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                qlik: orderdimensions.unloadinglocation.id,
                value: 'vD_Order.UnloadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.unloadinglocation.name,
                value: 'vD_Order.UnloadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.unloadinglocation.zipcode,
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.unloadinglocation.city,
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.unloadinglocation.country,
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land'
              },
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: ritdimensions.trip.number,
            value: 'vD_Trip.TripNumber',
            label: 'Ritnummer'
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                qlik: ritdimensions.driver.name,
                label: 'Naam',
                value: 'vD_Driver.Name'
              },
              {
                qlik: ritdimensions.driver.number, 
                label: 'Nummer',
                value: 'vD_Driver.Number'
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                qlik: ritdimensions.vehicle.number,
                label: 'Nummer',
                value: 'vD_Vehicle.Number'
              },
              {
                qlik: ritdimensions.vehicle.license,
                label: 'Kenteken',
                value: 'vD_Vehicle.License'
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                qlik: ritdimensions.charter.name,
                label: 'Naam',
                value: 'vD_Trip.Creditor.Name'
              },
              {
                qlik: ritdimensions.charter.number,
                label: 'Nummer',
                value: 'vD_Trip.Creditor.Number'
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                qlik: ritdimensions.trailer.number, 
                label: 'Nummer',
                value: 'vD_Trip.Trailer.Number'
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: orderdimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: orderdimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: orderdimensions.period.month,
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: orderdimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: orderdimensions.period.yearweek,
            value: 'vD_YearWeek',
            label: 'Jaar-Week'
          },
          {
            qlik: orderdimensions.period.date,
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal,
            value: 'vE_OrderTripOmzet',
            label: 'Totaal'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal,
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
            help: 'Totaal van de directe kosten.'
          },
          {
            qlik: measures.kosten.charter,
            value: 'vE_OrderTripKostenCharter',
            label: 'Derden',
            help: 'Kosten van zowel Italië charters (Excel) en expeditie (Winsped).'
          },
          {
            qlik: measures.kosten.rail,
            value: 'vE_OrderTripKostenRailFerry',
            label: 'Trein',
            help: 'Voorgecalculeerde kosten van treintrajecten uit de tarieventabel van Winsped'
          },
          {
            qlik: measures.kosten.toll,
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
            help:'Kosten van tol uit Winsped.'
          },
          {
            qlik: measures.kosten.collect,
            value: 'vE_OrderTripKostenCleaning',
            label: 'Cleaning',
            help:'Cleaning kosten uit Winsped, mochten die er niet zijn en er wel een cleaning gepland is wordt er met een standaard tarief gerekend.'
          },
          {
            qlik: measures.kosten.warehouse,
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Containerkosten',
            help: 'Voorgecalculeerde containerkosten die berekend worden door een tarief per dag te vermenigvuldigen met de dagen die een container in gebruik is. De container wordt gekoppeld aan een order na de laadactie van de order. De kosten lopen door op de order zo lang er geen nieuwe order geladen is. Eventuele hoge containerkosten op order niveau kan er op wijzen dat de container langere tijd niet geladen is.'
          },
          {
            qlik: measures.kosten.other,
            value: 'vE_OrderTripKostenOther',
            label: 'Overig',
            help: 'Overige kosten uit Winsped'
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.orders,
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
            help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
          },
          {
            qlik: measures.aantal.ritten,
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
            help: 'Het aantal ritten behorend bij de geselecteerde ritten. Hier zien we altijd het totaal. '
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur,
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK',
            help: 'Het percentage trajecten dat goedgekeurd is. Wanneer er iets misgaat met een rit wordt deze niet meegenomen in de kengetallen.'
          },

          {
            qlik: measures.kpi.normmarginpercent,
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Norm Marge %',
            help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Norm Marge',
            help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'   
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur',
            help: 'De omzet / uur is de omzet van het eigen werk minus de directe-kosten van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde trajecten. '
          },
          {
            qlik: measures.own.bezetting,
            value: 'vE_KPI_OrderTripBezetting',
            label: 'Bezetting'
          },
          {
            qlik: measures.own.normomzetperuur,
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur',
            help: 'De geconfigureerde norm op trajectniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed',
            help: 'Het percentage trajecten dat is uitbesteed.'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge',
            help: 'De marge op het uitbestede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de rit goedgekeurd is. '
          },
          {
            qlik: measures.outsourced.norm,
            value: 'vE_OrderTripNormCharter',
            label: 'Norm',
            help: 'De geconfigureerde norm op trajectniveau voor het uitbestede werk. De Derden Marge wordt getoetst aan deze norm. '
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.km,
            value: 'vE_OrderTripKM',
            label: 'Totaal',
            help: 'Geeft de totale kilometers op trajectniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.km.kmfull,
            value: 'vE_OrderTripKMFull',
            label: 'Beladen',
            help: 'Geeft de beladen kilometers op trajectniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.km.kmempty,
            value: 'vE_OrderTripKMEmpty',
            label: 'Onbeladen',
            help: 'Geeft de onbeladen kilometers op trajectniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.omzetperkmfull,
            value: 'vE_KPI_OrderTripOmzetPerKMFull',
            label: 'Omzet / beladen KM'
          },
          {
            qlik: measures.km.belading,
            value: 'vE_KPI_OrderTripBelading',
            label: '% Beladen'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM',
            help: 'De geconfigureerde norm op trajectniveau voor de Omzet / KM.'

          },
          {
            qlik: measures.km.normomzetperkmfull,
            value: 'vE_OrderTripNormTurnoverKMFull',
            label: 'Norm Omzet / beladen KM',
            help: 'De geconfigureerde norm op trajectniveau voor de Omzet / beladen KM.'

          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
            help: 'Het totaal aantal uren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
            help: 'Het totaal aantal laaduren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
            help: 'Het totaal aantal losuren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.uren.driving,
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
            help: 'Het totaal aantal rijuren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.uren.other,
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
            help: 'Het totaal aantal overige uren op trajectniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
            help: 'Het gemiddeld aantal uren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
            help: 'Het gemiddeld aantal laaduren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
            help: 'Het gemiddeld aantal losuren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
            help: 'Het gemiddeld aantal rijuren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)',     
            help: 'Het gemiddeld aantal overige uren per traject uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

          },

        ]
      },
    ]
  }
];
