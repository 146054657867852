import qlik from '../orders/qlik';
import {dimensions, measures} from './qlik/qlik';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            label: 'Naam',
            value: 'vD_Driver.Name'
          },
          {
            label: 'Nummer',
            value: 'vD_Driver.Number'
          }
        ]
      },
      {
        label: 'Auto',
        value: 'vehicle',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Vehicle.Number'
          },
          {
            label: 'Naam',
            value: 'vD_Vehicle.Name'
          },
          {
            label: 'Type',
            value: 'vD_Trip.Vehicle.Type'
          },
        ]
      },
      {
        label: 'Crediteur',
        value: 'crediteur',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Trip.Creditor.Number'
          },
          {
            label: 'Naam',
            value: 'vD_Trip.Creditor.Name'
          },
          {
            label: 'Soort', 
            value: 'vD_Trip.Creditor.Kind.Description'
          },
          {
            label: 'Zoekcode', 
            value: 'vD_Trip.Creditor.SearchCode'
          }
          
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            label: 'Nummer',
            value: 'vD_Trip.Trailer.Number'
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            value: 'vD_Trip.TripNumber',
            label: 'Rit Nummer'
          },
          {
            value: 'vD_Trip.ResourceCombination',
            label: 'Resource Combination'
          },
          {
            value: 'vD_Trip.PlanGroup',
            label: 'Plangroep'
          },
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'vD_Trip.StartLocation.Name',
            label: 'Naam'
          },
          {
            value: 'vD_Trip.StartLocation.Adress',
            label: 'Adres'
          },
          {
            value: 'vD_Trip.StartLocation.SearchCode',
            label: 'Zoekcode'
          },
          {
            value: 'vD_Trip.StartLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            value: 'vD_Trip.StartLocation.City',
            label: 'Plaats'
          },
          {
            value: 'vD_Trip.StartLocation.District',
            label: 'Gemeente'
          },
          {
            value: 'vD_Trip.StartLocation.Province',
            label: 'Provincie'
          },
          {
            value: 'vD_Trip.StartLocation.Country',
            label: 'Land'
          },
          {
            value: 'vD_Trip.StartLocation.Land',
            label: 'Land (ISO)'
          }
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'vD_Trip.EndLocation.Name',
            label: 'Name'
          },
          {
            value: 'vD_Trip.EndLocation.Adress',
            label: 'Adres'
          },
          {
            value: 'vD_Trip.EndLocation.SearchCode',
            label: 'Zoekcode'
          },
          {
            value: 'vD_Trip.EndLocation.ZIPcode',
            label: 'Postcode'
          },
          {
            value: 'vD_Trip.EndLocation.City',
            label: 'Plaats'
          },
          {
            value: 'vD_Trip.EndLocation.District',
            label: 'Gemeente'
          },
          {
            value: 'vD_Trip.EndLocation.Province',
            label: 'Provincie'
          },
          {
            value: 'vD_Trip.EndLocation.Country',
            label: 'Land'
          },
          {
            value: 'vD_Trip.EndLocation.Land',
            label: 'Land (ISO)'
          },
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            value: 'vD_Week',
            label: 'Week'
          },
          {
            value: 'vD_Date',
            label: 'Datum'
          },
          {
            value: 'vD_Weekday',
            label: 'Dag'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            value: 'vE_TripOmzet',
            label: 'Totaal'
          },
          {
            value: 'vE_TripOmzet.Freight',
            label: 'Vracht'
          },
          {
            value: 'vE_TripOmzet.Fuel',
            label: 'Brandstof'
          },
          {
            value: 'vE_TripOmzet.ExtraKosten',
            label: 'Extra Kosten'
          },
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            value: 'vE_TripKosten',
            label: 'Totaal',
          },
          {
            value: 'vE_TripKostenCharter',
            label: 'Vracht',
          },
          {
            value: 'vE_TripKostenTol',
            label: 'Tol',
          },
          {
            value: 'vE_TripKostenWarehouse',
            label: 'Crossdock',
          },
          {
             value: 'vE_TripKostenOther',
             label: 'Other',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            value: 'vE_TripAantalDossiers',
            label: 'Dossiers',
          },
          {
            value: 'vE_TripAantalOrders',
            label: 'Zendingen',
          },
          {
            value: 'vE_TripAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'vE_KPI_TripGoedkeur',
            label: 'OK'
          },

          {
            value: 'vE_KPI_TripNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            value: 'vE_KPI_TripNormMarginOverOmzet',
            label: 'Norm Marge'
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            value: 'vE_KPI_TripOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            value: 'vE_TripNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'vE_KPI_TripTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            value: 'vE_KPI_TripCharterMarge',
            label: 'Derden Marge'
          },
          {
            value: 'vE_TripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'vE_TripKM',
            label: 'Totaal'
          },
          {
            value: 'vE_KPI_TripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            value: 'vE_TripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'vE_TripUren',
            label: 'Totaal (Σ)',
          },
          {
            value: 'vE_TripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            value: 'vE_TripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            value: 'vE_TripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            value: 'vE_TripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            value: 'vE_TripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            value: 'vE_TripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            value: 'vE_TripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            value: 'vE_TripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            value: 'vE_TripUrenAvgOther',
            label: 'Overig (avg)',      // $(wActive(Order, 'M_Uren_Rijden_A'))
          }
        ]
      }
    ]
  }
];
