import qlik from '../orders/qlik';
import {dimensions, measures} from './qlik/qlik';
export default [
  {
    value: 'dimensions',
    label: 'Dimensions',
    class: 'bg-primary',
    children: [
      {
        label: 'Chauffeur',
        value: 'driver',
        children: [
          {
            label: 'Name',
            value: 'dimensions.driver.name',
            qlik: dimensions.driver.name
          },
          {
            label: 'Number',
            value: 'dimensions.driver.number',
            qlik: dimensions.driver.number
          }
        ]
      },
      {
        label: 'Vehicle',
        value: 'vehicle',
        children: [
          {
            label: 'Number',
            value: 'dimensions.vehicle.number',
            qlik: dimensions.vehicle.number
          },
          {
            label: 'License',
            value: 'dimensions.vehicle.name',
            qlik: dimensions.vehicle.name
          }
        ]
      },
      {
        label: 'Charter',
        value: 'crediteur',
        children: [
          {
            label: 'Number',
            value: 'dimensions.charter.number',
            qlik: dimensions.charter.number
          },
          {
            label: 'Name',
            value: 'dimensions.charter.name',
            qlik: dimensions.charter.name
          },
        ]
      },
      {
        label: 'Trailer',
        value: 'trailer',
        children: [
          {
            label: 'Number',
            value: 'dimensions.trailer.number',
            qlik: dimensions.trailer.number
          },
        ]
      },
      {
        label: 'Trip',
        value: 'rit',
        children: [
          {
            value: 'dimensions.trip.number',
            label: 'Trip Nummer',
            qlik: dimensions.trip.number
          }
        ]
      },
      {
        label: 'Start Locatie',
        value: 'laadlocatie',
        children: [
          {
            value: 'dimensions.startlocation.name',
            label: 'Naam',
            qlik: dimensions.startlocation.name
          },
          {
            value: 'dimensions.startlocation.id',
            label: 'ID',
            qlik: dimensions.startlocation.id
          },
          {
            value: 'dimensions.startlocation.zipcode',
            label: 'Postalcode',
            qlik: dimensions.startlocation.zipcode
          },
          {
            value: 'dimensions.startlocation.city',
            label: 'City',
            qlik: dimensions.startlocation.city
          },
          {
            value: 'dimensions.startlocation.country',
            label: 'Country',
            qlik: dimensions.startlocation.country
          }
        ]
      },
      {
        label: 'Eind Locatie',
        value: 'loslocatie',
        children: [
          {
            value: 'dimensions.endlocation.name',
            label: 'Name',
            qlik: dimensions.endlocation.name
          },
          {
            value: 'dimensions.endlocation.id',
            label: 'ID',
            qlik: dimensions.endlocation.id
          },
          {
            value: 'dimensions.endlocation.zipcode',
            label: 'Postalcode',
            qlik: dimensions.endlocation.zipcode
          },
          {
            value: 'dimensions.endlocation.city',
            label: 'City',
            qlik: dimensions.endlocation.city
          },
          {
            value: 'dimensions.endlocation.country',
            label: 'Country',
            qlik: dimensions.endlocation.country
          }
        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            value: 'dimensions.period.year',
            label: 'Year',
            qlik: dimensions.period.year
          },
          {
            value: 'dimensions.period.quarter',
            label: 'Quarter',
            qlik: dimensions.period.quarter
          },
          {
            value: 'dimensions.period.month',
            label: 'Month',
            qlik: dimensions.period.month
          },
          {
            value: 'dimensions.period.period',
            label: 'Period',
            qlik: dimensions.period.period
          },
          {
            value: 'dimensions.period.week',
            label: 'Week',
            qlik: dimensions.period.week
          },
          {
            value: 'dimensions.period.date',
            label: 'Date',
            qlik: dimensions.period.date
          }
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Turnover',
        children: [
          {
            value: 'measures.omzet.totaal',
            label: 'Total',
            qlik: measures.omzet.totaal
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Costs',
        children: [
          {
            value: 'measures.kosten.totaal',
            label: 'Total',
            qlik: measures.kosten.totaal
          },
          {
            value: 'measures.kosten.charter',
            label: 'Charter',
            qlik: measures.kosten.charter
          },
          {
            value: 'measures.kosten.toll',
            label: 'Toll',
            qlik: measures.kosten.toll
          },
          {
            value: 'measures.kosten.warehouse',
            label: 'Warehouse',
            qlik: measures.kosten.warehouse
          },
          {
            value: 'measures.kosten.rail',
            label: 'Rail / Ferry',
            qlik: measures.kosten.rail,
            help: 'Trein kosten vanuit Transpas voor de reservering van de rail kosten per zending'
          },
          {
             value: 'measures.kosten.other',
             label: 'Other',
             qlik: measures.kosten.other
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Count',
        children: [
          {
            value: 'measures.aantal.orders',
            label: 'Jobs',
            qlik: measures.aantal.orders
          },
          {
            value: 'measures.aantal.ritten',
            label: 'Trips',
            qlik: measures.aantal.ritten
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            value: 'measures.kpi.goedkeur',
            label: 'OK',
            qlik: measures.kpi.goedkeur
          },

          {
            value: 'measures.kpi.normmarginpercent',
            label: 'Norm Marge %',
            qlik: measures.kpi.normmarginpercent
          },
          {
            value: 'measures.kpi.normmarginomzet',
            label: 'Norm Marge',
            qlik: measures.kpi.normmarginomzet
          },


        ]
      },
      {
        value: 'eigenwagen',
        label: 'Own Vehicle',
        children: [
          {
            value: 'measures.own.omzetperuur',
            label: 'TO / Hour',
            qlik: measures.own.omzetperuur
          },
          {
            value: 'measures.own.normomzetperuur',
            label: 'Norm TO / Hour',
            qlik: measures.own.normomzetperuur
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            value: 'measures.outsourced.percentage',
            label: '% Charter',
            qlik: measures.outsourced.percentage
          },
          {
            value: 'measures.outsourced.marge',
            label: 'Charter Margin',
            qlik: measures.outsourced.marge
          },
          {
            value: 'measures.outsourced.norm',
            label: 'Norm',
            qlik: measures.outsourced.norm
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            value: 'measures.km.km',
            label: 'Total',
            qlik: measures.km.km
          },
          {
            value: 'measures.km.omzetperkm',
            label: 'TO / KM',
            qlik: measures.km.omzetperkm
          },
          {
            value: 'measures.km.normomzetperkm',
            label: 'Norm TO / KM',
            qlik: measures.km.normomzetperkm
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            value: 'measures.uren.totaal',
            label: 'Total (Σ)',
            qlik: measures.uren.totaal
          },
          {
            value: 'measures.uren.loading',
            label: 'Loading (Σ)',
            qlik: measures.uren.loading
          },
          {
            value: 'measures.uren.unloading',
            label: 'Unloading (Σ)',
            qlik: measures.uren.unloading
          },
          {
            value: 'measures.uren.driving',
            label: 'Drive (Σ)',
            qlik: measures.uren.driving
          },
          {
            value: 'measures.uren.other',
            label: 'Other (Σ)',
            qlik: measures.uren.other
          },
          {
            value: 'measures.uren.avg_totaal',
            label: 'Total (avg)',
            qlik: measures.uren.avg_totaal
          },
          {
            value: 'measures.uren.avg_loading',
            label: 'Loading (avg)',
            qlik: measures.uren.avg_loading
          },
          {
            value: 'measures.uren.avg_unloading',
            label: 'Unloading (avg)',
            qlik: measures.uren.avg_unloading
          },
          {
            value: 'measures.uren.avg_driving',
            label: 'Drive (avg)',
            qlik: measures.uren.avg_driving
          },
          {
            value: 'measures.uren.avg_other',
            label: 'Other (avg)', 
            qlik: measures.uren.avg_other     // $(wActive(Order, 'M_Uren_Rijden_A'))
          }
        ]
      },
      {
        value: 'vergelijkperiode',
        label: 'Previous Year',
        children: [
          {
            value: 'measures.previousyear.omzet',
            label: 'Turnover',
            qlik: measures.previousyear.omzet
          },
          {
            value: 'measures.previousyear.orders',
            label: 'Jobs',
            qlik: measures.previousyear.orders
          },
          {
            value: 'measures.previousyear.ritten',
            label: 'Trips',
            qlik: measures.previousyear.ritten
          },
          {
            value: 'measures.previousyear.goedkeur',
            label: 'OK',
            qlik: measures.previousyear.goedkeur
          },

          {
            value: 'measures.previousyear.normmarge',
            label: 'Norm Marge %',
            qlik: measures.previousyear.normmarge
          },
          {
            value: 'measures.previousyear.normmargeomzet',
            label: 'Norm Marge',
            qlik: measures.previousyear.normmargeomzet
          },
          {
            value: 'measures.previousyear.omzetperuur',
            label: 'TO / Hour',
            qlik: measures.previousyear.omzetperuur
          },
          {
            value: 'measures.previousyear.uitbesteed',
            label: '% Charter',
            qlik: measures.previousyear.uitbesteed
          },
          {
            value: 'measures.previousyear.chargermarge',
            label: 'Charter Marge',
            qlik: measures.previousyear.chargermarge
          },
          {
            value: 'zendinggrootte',
            label: 'ShipmentSize',
            children: [
          {
            value: 'measures.previousyear.avg_lm',
            label: 'Average LM',
            qlik: measures.previousyear.avg_lm
          },
          {
            value: 'measures.previousyear.lm',
            label: 'Total LM',
            qlik: measures.previousyear.lm
          },
        ],
          },

        ],
      },
    ]
  }
];
