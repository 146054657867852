import React from 'react';
import orderList from './../../margemonitor/components/orders/dimensions-VRE';
import ritList from './../../margemonitor/components/ritten/dimensions-VRE';
import orderTripList from './../../margemonitor/components/trajecten/dimensions-VRE';


const Overall = React.lazy(() => import('./../../views/Maatwerk/VRE/Overall'));//./../../views/Maatwerk/VRE/Overall'));
const OmzetTrend = React.lazy(() => import('./../../views/Maatwerk/VRE/OmzetTrend'));
const Rapportage = React.lazy(() => import('./../../views/Maatwerk/VRE/Rapportage'));
const KorteLaadLostijden = React.lazy(() => import('./../../views/Maatwerk/VRE/KorteLaadLostijden'));
const Uitbesteed = React.lazy(() => import('./../../views/Maatwerk/VRE/Uitbesteed'));
const Draaitabel = React.lazy(() => import('./../../views/Maatwerk/VRE/Draaitabel'));
const Omzetontwikkeling = React.lazy(() => import('./../../views/Maatwerk/VRE/Omzetontwikkeling'));
const Steen = React.lazy(() => import('./../../views/Maatwerk/VRE/Steen'));

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'qliksense.vredeveld.eu', 
    secure: true,
    //port: 443,
    prefix: '',
    //prefix: 'forms',
    appId: '69027c27-f0b5-4f8c-9c88-138ef0457ab8',
  },
  connections: {
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Year)]'],
            placeholder: 'Jaar',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Quarter)]'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Month)]'],
            placeholder: 'Maand',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Week)]'],
            placeholder: 'Week',
            autoSortByState: 0,
          },
        },  
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Date)]'],
            placeholder: 'Datum',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Ordernummer',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'title',
          title: 'Klant'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Order.Debtor.Name)'],
            placeholder: 'Klantnaam',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'DebiteurNummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.DebiteurGroep)]'],
            placeholder: 'Debiteur Groep',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: 'Afdeling'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.KindDescription)]'],
            placeholder: 'Order soort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.ReportDepartment)]'],
            placeholder: 'Rapportage-afdeling',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.PlanDepartment)]'],
            placeholder: 'Plan-afdeling',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Bedrijf)]'],
            placeholder: 'Bedrijf',
            showStateInDropdown: true,
          },
        }
        
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Chartersoort',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Crediteur nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Crediteur naam',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        title: true,
        name: 'Maatwerk',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: 'Overall',
        url: '/vre/overall',
        icon: 'fa fa-truck',
      },
      {
        name: 'Rapportage',
        url: '/vre/rapportage',
        icon: 'fa fa-euro-sign',
      },
      {
        name: 'Omzet trend',
        url: '/vre/omzet-trend',
        icon: 'fa fa-percentage',
      },
      {
       name: 'Korte laad/lostijden',
       url: '/vre/korte-laad-lostijden',
       icon: 'fa fa-truck-loading',
      },
      // {
      //   name: 'Uitbesteed',
      //   url: '/vre/uitbesteed',
      //   icon: 'fa fa-building',
      // },
      {
        name: 'Draaitabel (tbv Avebe)',
        url: '/vre/draaitabel',
        icon: 'fa fa-table',
      },
      {
        name: 'Omzetontwikkeling',
        url: '/vre/omzetontwikkeling',
        icon: 'fa fa-chart-line',
      },
      {
        name: 'Bouw',
        url: '/vre/steen',
        icon: 'fa fa-hammer',
      },
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: 'Weekrapport', 
        url: 'https://qliksense.vredeveld.eu/extensions/Report/Report.html',
        icon: 'fa fa-file',
        attributes: { target: '_blank', active: false },
      }
    ],
  },
  routes: [
    { path: '/vre/overall', name: 'VRE / Overall', component: Overall },
    { path: '/vre/omzet-trend', name: 'VRE / Overall', component: OmzetTrend },
    { path: '/vre/rapportage', name: 'VRE / Rapportage', component: Rapportage },
    { path: '/vre/korte-laad-lostijden', name: 'VRE / Korte laad/lostijden', component: KorteLaadLostijden },
    { path: '/vre/uitbesteed', name: 'VRE / Uitbesteed', component: Uitbesteed },
    { path: '/vre/draaitabel', name: 'VRE / Draaitabel', component: Draaitabel },
    { path: '/vre/omzetontwikkeling', name: 'VRE / Omzetontwikkeling', component: Omzetontwikkeling },
    { path: '/vre/steen', name: 'VRE / Steen', component: Steen }

  ],
  fieldSettings:{
    period: 'vD_Month'
  },
};

export default options;
