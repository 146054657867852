import React from 'react';

import orderList from '../../margemonitor/components/orders/dimensions-LUB';
import ritList from '../../margemonitor/components/ritten/dimensions-LUB';
import orderTripList from '../../margemonitor/components/trajecten/dimensions-LUB';




const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'qlik.lubbers.net',
    secure: true,
    port: '443',
    prefix: '',
    //webIntegrationId: 'UC7aldzKKXMMGqblEErpfq2xZ-LgCxNv',  
    appId: "8c60a2ab-1de1-45fe-bba6-2060ecd2ec91",
    //appId: "c6b69f9e-59c7-440b-a7f7-58309c28e5da"
  },
  connections: {
    qlikCloud: false, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Year)'],
            placeholder: 'Year',
            expanded: true, 
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Quarter)'],
            placeholder: 'Quarter',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Period)'],
            placeholder: 'Period',
            expandedHorizontal: true,
            expanded: true, 
            expandedHorizontalSense: true,
            qSortByAscii: 1,
            autoSortByState: 0,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_YearWeek)'],
            placeholder: 'Year-Week',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Date',
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Job - LoadingLocation'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Name)]'],
            placeholder: 'Name',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postalcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Country',
          },
        },
        {
          type: 'title',
          title: 'Job - UnloadingLocation'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Name)]'],
            placeholder: 'Name',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postalcode',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Country',
          },
        },
      ],
    },
    order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CompanyName)]'],
            placeholder: 'Company Name',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CompanyNumber)]'],
            placeholder: 'Company Number',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Job',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        { 
          type: 'title',
          title: 'Debtor'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Name',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Number',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.GroupAccountName)]'],
            placeholder: 'Group',
            showStateInDropdown: true,
          },
        },
        {
          type: 'title',
          title: 'Characteristics'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.GroupVerticalName)]'],
            placeholder: 'Vertical',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.CIP.AccountManagerName)]'],
            placeholder: 'Account Manager',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.ServiceDescription)]'],
            placeholder: 'Service',
            showStateInDropdown: true,
            autoSortByState: 1
          },
        },
        
      ],
    },
    trip: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Kenmerken" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.ResourceCombination)]'],
            placeholder: 'Resource Combination',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.PlanGroup)]'],
            placeholder: 'Plangroep',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Vehicle.Type)]'],
            placeholder: 'Type',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.SearchCode)]'],
            placeholder: 'Zoekcode',
            showStateInDropdown: true,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Soort',
            showStateInDropdown: true,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: []
  }, 
  routes: [
  ],
  fieldSettings:{
    period: 'vD_Month'
  },
  defaultSelections: [
    {
      field: 'Year',
      expression: '=year(today())'
    }
  ]

};

export default options;
