import {measures} from './qlik/qlik';
import { dimensions as ritdimensions } from '../ritten/qlik/qlik';
import { dimensions as orderdimensions } from '../orders/qlik/klant/klant';
export default [
  {
    value: 'dimensions',
    label: 'Dimensies',
    class: 'bg-primary',
    children: [
      {
        label: 'Order',
        value: 'order',
        children: [
          {
            qlik: orderdimensions.order.ordernumberdisplay,
            value: 'vD_Order.OrderNumberDisplay',
            label: 'Order Nummer'
          }, 
          {
            label: 'Klant',
            value: 'klant',
            children: [
              {
                qlik: orderdimensions.debtor.name, 
                label: 'Naam',
                value: 'vD_Order.Debtor.Name'
              },
              {
                qlik: orderdimensions.debtor.number, 
                label: 'Nummer',
                value: 'vD_Order.Debtor.Number'
              }
            ]
          },
          {
            label: 'Laad Locatie',
            value: 'laadlocatie',
            children: [
              {
                qlik: orderdimensions.loadinglocation.id, 
                value: 'vD_Order.LoadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.loadinglocation.name, 
                value: 'vD_Order.LoadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.loadinglocation.adress,
                value: 'vD_Order.LoadingLocation.Adress',
                label: 'Adres'
              },
              {
                qlik: orderdimensions.loadinglocation.zipcode,
                value: 'vD_Order.LoadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.loadinglocation.city, 
                value: 'vD_Order.LoadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.loadinglocation.land, 
                value: 'vD_Order.LoadingLocation.Country',
                label: 'Land'
              },
            ]
          },
          {
            label: 'Los Locatie',
            value: 'loslocatie',
            children: [
              {
                qlik: orderdimensions.unloadinglocation.id, 
                value: 'vD_Order.UnloadingLocation.ID',
                label: 'ID'
              },
              {
                qlik: orderdimensions.unloadinglocation.name, 
                value: 'vD_Order.UnloadingLocation.Name',
                label: 'Naam'
              },
              {
                qlik: orderdimensions.unloadinglocation.adress,
                value: 'vD_Order.UnloadingLocation.Adress',
                label: 'Adres'
              },
              {
                qlik: orderdimensions.unloadinglocation.zipcode,
                value: 'vD_Order.UnloadingLocation.ZIPcode',
                label: 'Postcode'
              },
              {
                qlik: orderdimensions.unloadinglocation.city, 
                value: 'vD_Order.UnloadingLocation.City',
                label: 'Plaats'
              },
              {
                qlik: orderdimensions.unloadinglocation.country, 
                value: 'vD_Order.UnloadingLocation.Country',
                label: 'Land'
              }
            ]
          },
        ]
      },
      {
        label: 'Rit',
        value: 'rit',
        children: [
          {
            qlik: ritdimensions.trip.number,
            value: 'vD_Trip.TripNumber',
            label: 'Ritnummer'
          },
          {
            label: 'Chauffeur',
            value: 'driver',
            children: [
              {
                qlik: ritdimensions.driver.name, 
                label: 'Naam',
                value: 'vD_Driver.Name'
              },
              {
                qlik: ritdimensions.driver.number, 
                label: 'Nummer',
                value: 'vD_Driver.Number'
              }
            ]
          },
          {
            label: 'Auto',
            value: 'vehicle',
            children: [
              {
                qlik: ritdimensions.vehicle.number, 
                label: 'Naam',
                value: 'vD_Vehicle.Number'
              },
              {
                qlik: ritdimensions.vehicle.name, 
                label: 'Nummer',
                value: 'vD_Vehicle.Name'
              }
            ]
          },
          {
            label: 'Crediteur',
            value: 'crediteur',
            children: [
              {
                qlik: ritdimensions.charter.name, 
                label: 'Naam',
                value: 'vD_Trip.Creditor.Name'
              },
              {
                qlik: ritdimensions.charter.number, 
                label: 'Nummer',
                value: 'vD_Trip.Creditor.Number'
              }
            ]
          },
          {
            label: 'Trailer',
            value: 'trailer',
            children: [
              {
                qlik: ritdimensions.trailer.number, 
                label: 'Nummer',
                value: 'vD_Trip.Trailer.Number'
              },
            ]
          },

        ]
      },
      {
        label: 'Periode',
        value: 'periode',
        children: [
          {
            qlik: ritdimensions.period.year,
            value: 'vD_Year',
            label: 'Jaar'
          },
          {
            qlik: ritdimensions.period.quarter,
            value: 'vD_Quarter',
            label: 'Kwartaal'
          },
          {
            qlik: ritdimensions.period.month, 
            value: 'vD_Month',
            label: 'Maand'
          },
          {
            qlik: ritdimensions.period.week,
            value: 'vD_Week',
            label: 'Week'
          },
          {
            qlik: ritdimensions.period.date, 
            value: 'vD_Date',
            label: 'Datum'
          },
        ]
      }
    ]
  },
  {
    label: 'Meetwaarden',
    value: 'measures',
    class: 'bg-danger',
    children: [
      {
        value: 'omzet',
        label: 'Omzet',
        children: [
          {
            qlik: measures.omzet.totaal, 
            value: 'vE_OrderTripOmzet',
            label: 'Totaal'
          }
        ]
      },
      {
        value: 'kosten',
        label: 'Kosten',
        children: [
          {
            qlik: measures.kosten.totaal, 
            value: 'vE_OrderTripKosten',
            label: 'Totaal',
          },
          {
            qlik: measures.kosten.charter, 
            value: 'vE_OrderTripKostenCharter',
            label: 'Charter',
          },
          {
            qlik: measures.kosten.toll, 
            value: 'vE_OrderTripKostenTol',
            label: 'Tol',
          },
          {
            qlik: measures.kosten.warehouse, 
            value: 'vE_OrderTripKostenWarehouse',
            label: 'Warehouse',
          },
          {
            qlik: measures.kosten.other, 
            value: 'vE_OrderTripKostenOther',
            label: 'Overig',
          },
        ]
      },
      {
        value: 'aantal',
        label: 'Aantal',
        children: [
          {
            qlik: measures.aantal.dossiers, 
            value: 'vE_OrderTripAantalDossiers',
            label: 'Dossiers',
          },
          {
            qlik: measures.aantal.orders, 
            value: 'vE_OrderTripAantalOrders',
            label: 'Zendingen',
          },
          {
            qlik: measures.aantal.ritten, 
            value: 'vE_OrderTripAantalRitten',
            label: 'Ritten',
          },
        ]
      },
      {
        value: 'kpi',
        label: 'KPI',
        children: [
          {
            qlik: measures.kpi.goedkeur, 
            value: 'vE_KPI_OrderTripGoedkeur',
            label: 'OK'
          },
          {
            qlik: measures.kpi.normmarginpercent, 
            value: 'vE_KPI_OrderTripNormMarginPercent',
            label: 'Norm Marge %'
          },
          {
            qlik: measures.kpi.normmarginomzet,
            value: 'vE_KPI_OrderTripNormMarginOverOmzet',
            label: 'Norm Marge'
          },
        ]
      },
      {
        value: 'eigenwagen',
        label: 'Eigen Wagen',
        children: [
          {
            qlik: measures.own.omzetperuur,
            value: 'vE_KPI_OrderTripOmzetPerUur',
            label: 'Omzet / Uur'
          },
          {
            qlik: measures.own.normomzetperuur, 
            value: 'vE_OrderTripNormTurnoverHour',
            label: 'Norm Omzet / Uur'
          },
        ]
      },
      {
        value: 'uitbesteed',
        label: 'Uitbesteed',
        children: [
          {
            qlik: measures.outsourced.percentage,
            value: 'vE_KPI_OrderTripOutsourced',
            label: '% Uitbesteed'
          },
          {
            qlik: measures.outsourced.marge,
            value: 'vE_KPI_OrderTripCharterMarge',
            label: 'Derden Marge'
          },
          {
            qlik: measures.outsourced.norm, 
            value: 'vE_OrderTripNormCharter',
            label: 'Norm'
          },
        ]
      },
      {
        value: 'km',
        label: 'KM',
        children: [
          {
            qlik: measures.km.totaal, 
            value: 'vE_OrderTripKM',
            label: 'Totaal'
          },
          {
            qlik: measures.km.omzetperkm,
            value: 'vE_KPI_OrderTripOmzetPerKM',
            label: 'Omzet / KM'
          },
          {
            qlik: measures.km.normomzetperkm,
            value: 'vE_OrderTripNormTurnoverKM',
            label: 'Norm Omzet / KM'
          },
        ]
      },
      {
        value: 'uren',
        label: 'Uren',
        children: [
          {
            qlik: measures.uren.totaal,
            value: 'vE_OrderTripUren',
            label: 'Totaal (Σ)',
          },
          {
            qlik: measures.uren.loading,
            value: 'vE_OrderTripUrenLoading',
            label: 'Laden (Σ)',
          },
          {
            qlik: measures.uren.unloading,
            value: 'vE_OrderTripUrenUnloading',
            label: 'Lossen (Σ)',
          },
          {
            qlik: measures.uren.driving, 
            value: 'vE_OrderTripUrenDriving',
            label: 'Rijden (Σ)',
          },
          {
            qlik: measures.uren.other, 
            value: 'vE_OrderTripUrenOther',
            label: 'Overig (Σ)',
          },
          {
            qlik: measures.uren.avg_totaal,
            value: 'vE_OrderTripUrenAvg',
            label: 'Totaal (avg)',
          },
          {
            qlik: measures.uren.avg_loading,
            value: 'vE_OrderTripUrenAvgLoading',
            label: 'Laden (avg)',
          },
          {
            qlik: measures.uren.avg_unloading,
            value: 'vE_OrderTripUrenAvgUnloading',
            label: 'Lossen (avg)',
          },
          {
            qlik: measures.uren.avg_driving,
            value: 'vE_OrderTripUrenAvgDriving',
            label: 'Rijden (avg)',
          },
          {
            qlik: measures.uren.avg_other,
            value: 'vE_OrderTripUrenAvgOther',
            label: 'Overig (avg)', 
          },
        ]
      },
    ]
  }
];
