import React from 'react';

import orderList from './../../margemonitor/components/orders/dimensions-WIJ';
import ritList from './../../margemonitor/components/ritten/dimensions-WIJ';
import orderTripList from './../../margemonitor/components/trajecten/dimensions-WIJ';

const KM_aansluiting = React.lazy(() => import('./../../views/Maatwerk/WIJ/KM-aansluiting'));
const UrenControle = React.lazy(() => import('./../../views/Maatwerk/WIJ/ControleUren'));
const NarekenenKPI = React.lazy(() => import('./../../views/Maatwerk/ALGEMEEN/NarekenenKPI'));

const options = {
  dimensions: {
    order: orderList,
    rit: ritList,
    traject: orderTripList,
  },
  config: {
    host: 'transportcostconsult.eu.qlikcloud.com',
    //host: 'qliksense.evanwijk.com',
    secure: true,
    prefix: '',
    theme: 'evanwijk',
    webIntegrationId: 'NhBp7EoCvt99qxuBCQWpiI_GDISmLc7U',  
    appId: '3da3ac37-2c5a-493b-a034-cc0322ce8635', // Cloud
    //appId: 'c8064573-a7f2-4bd1-804c-b9a109328f66', // Development

    //appId: 'b65d860e-4a3f-4cb4-af88-1cd48a6341c2', 
  },
  connections: {
    qlikCloud: true, 
    vizApi: true,
    engineApi: true,
    useUniqueSessionID: 'MargeMonitor'
  },
  filters: {
    calendar: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Year)]'],
            placeholder: 'Jaar',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Month)]'],
            placeholder: 'Maand',
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Quarter)]'],
            placeholder: 'Kwartaal',
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            autoSortByState: 0,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_WeekDay)]'],
            expanded: true,
            expandedHorizontal: true,
            expandedHorizontalSense: true,
            placeholder: 'Dag',
            qSortByAscii: 1,
          }
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Week)'],
            placeholder: 'Week',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },   
        {
          type: 'QdtFilter',
          props: {
            cols: ['$(vD_Date)'],
            placeholder: 'Datum',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
               
      ],
    },
    location: {
      replace: false,
      filters: [
        {
          type: 'title',
          title: 'Order Laadlocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ID)]'],
            placeholder: 'ID',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Name)]'],
            placeholder: 'Naam',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Adress)]'],
            placeholder: 'Adres',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.City)]'],
            placeholder: 'City',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.District)]'],
            placeholder: 'Gemeente',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Province)]'],
            placeholder: 'Provincie',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Country)]'],
            placeholder: 'Land',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.LoadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'title',
          title: 'Order Loslocatie'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ID)]'],
            placeholder: 'ID',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Name)]'],
            placeholder: 'Naam',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Adress)]'],
            placeholder: 'Adres',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.ZIPcode)]'],
            placeholder: 'Postcode',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.City)]'],
            placeholder: 'City',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.District)]'],
            placeholder: 'Gemeente',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Province)]'],
            placeholder: 'Provincie',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Country)]'],
            placeholder: 'Land',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.UnloadingLocation.Land)]'],
            placeholder: 'Land (ISO)',
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
      ],
    },order: {
      replace: false,
      filters: [
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.OrderNumberDisplay)]'],
            placeholder: 'Ordernummer',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Dossier)]'],
            placeholder: 'Dossier',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Reference)]'],
            placeholder: 'Referentie',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Status)]'],
            placeholder: 'Status',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.PlanDivision)]'],
            placeholder: 'Soort Rit',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_OperationalOrder)]'],
            placeholder: 'Operational Order',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'title',
          title: 'Kenmerken'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Rejected)]'],
            placeholder: 'Afgekeurd',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'title',
          title: 'Klant'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Name)]'],
            placeholder: 'Naam',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Debtor.Number)]'],
            placeholder: 'Nummer',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        { 
          type: 'title',
          title: 'Afdeling'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Group)]'],
            placeholder: 'Afdeling',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.KindDescription)]'],
            placeholder: 'Ordersoort',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        { 
          type: 'title',
          title: 'Goederen'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Klasse.Size)]'],
            placeholder: 'Groote - Omschrijving',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[Order > Klasse - Grootte]'],
            placeholder: 'Klasse - Groote',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[Order > Klasse - LM]'],
            placeholder: 'Klasse - LM',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Order.Size.LM)]'],
            placeholder: 'LM',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
      ],
    },
    trip: {
      replace: false,
      filters: [
        { 
          type: 'title',
          title: "Rit" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.TripNumber)]'],
            placeholder: 'Rit nummer',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        { 
          type: 'title',
          title: "Auto" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Number)]'],
            placeholder: 'Auto nummer',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Vehicle.Name)]'],
            placeholder: 'Auto naam',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        { 
          type: 'title',
          title: "Chauffeur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Number)]'],
            placeholder: 'Chauffeur nummer',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Driver.Name)]'],
            placeholder: 'Chauffeur naam',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        { 
          type: 'title',
          title: "Crediteur" 
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[_ind_Trip.Outsourced]'],
            placeholder: 'Uitbesteed',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Kind.Description)]'],
            placeholder: 'Chartersoort',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Number)]'],
            placeholder: 'Crediteur nummer',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Creditor.Name)]'],
            placeholder: 'Crediteur naam',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'title',
          title: 'Kenmerken'
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.Rejected)]'],
            placeholder: 'Afgekeurd',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
        {
          type: 'QdtFilter',
          props: {
            cols: ['[$(vD_Trip.AdminServices)]'],
            placeholder: 'AdminServices',
            showStateInDropdown: true,
            autoSortByState: 1,
            qSortByAscii: 1,
          },
        },
      ],
    },
  },
  menu: {
    disable: [],
    menu: [
      {
        title: true,
        name: '',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        title: true,
        name: 'Maatwerk',
        wrapper: {
          element: '',
          attributes: {},
        },
      },
      {
        name: 'Controle',
        url: '/wij/controle',
        icon: 'fa fa-check',
        children: [
          {
            name: 'KM',
            url: '/wij/controle/km'
          },
          {
            name: 'Uren',
            url: '/wij/controle/uren'
          }
        ]
      }
    ]
  },
  routes: [
    { path: '/wij/controle/km', name: 'WIJ / Controle / KM', component: KM_aansluiting },
    { path: '/wij/controle/uren', name: 'WIJ / Controle / Uren', component: UrenControle },
  ],
  settings: {
    expandedFilter: true
  },
  fieldSettings:{
    period: 'vD_Month'
  },
  defaultSelections: [
    {
      field: 'Jaar',
      expression: '=year(today())'
    }
  ]
};

export default options;
